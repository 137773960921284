<template>
  <div>
    <!-- -------万恶分割线------- -->
    <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <!-- 表单 -->
      <el-form label-width="160px" label-position="right" ref="perForm" :model="formData" :rules="rules">
        <el-form-item label="公司logo" prop="companyLogoId">
          <el-upload class="avatar-uploader" action="http://up-z2.qiniup.com" :show-file-list="false"
            accept="image/jpeg,image/gif,image/png" :on-success="handleAvatarSuccess" :data="postData"
            :before-upload="beforeAvatarUpload">
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div style="margin-left: 10px; color: red; font-size: 12px">
              公司logo：建议尺寸76*76
            </div>
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司名称" prop="companyName">
              <el-input v-model="formData.companyName" placeholder="请输入公司名称" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账号管理员" prop="managerName">
              <el-input v-model="formData.managerName" placeholder="请输入账号管理员名称" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="会员主账号" prop="account">
              <el-input v-model="formData.account" placeholder="请输入会员主账号" style="width: 100%" @input="sc" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司联系电话" prop="tel">
              <el-input v-model="formData.tel" placeholder="请输入公司联系电话" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="缴费时间" prop="payDateMsg">
              <el-date-picker style="width: 100%" v-model="formData.payDateMsg" @change="change789"
                value-format="yyyy-MM-dd" type="date" placeholder="请选择缴费时间" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到期时间" prop="expireDateMsg">
              <el-date-picker style="width: 100%" v-model="formData.expireDateMsg" @change="change456"
                value-format="yyyy-MM-dd" type="date" placeholder="请选择到期时间" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="会员服务" prop="serviceId">
              <el-select style="width: 100%" v-model="formData.serviceId" placeholder="请选择会员服务" multiple
                @change="change0">
                <el-option v-for="(item, index) in list" :key="index" :label="item.serviceName" :value="item.serviceId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司简称" prop="companyAbbreviation">
              <el-input v-model="formData.companyAbbreviation" placeholder="请输入公司简称" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="网站地址" prop="websiteAddress">
              <el-input v-model="formData.websiteAddress" placeholder="请输入网站地址" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="formData.email" placeholder="请输入邮箱" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="公司地址" prop="companyAddress">
              <el-input v-model="formData.companyAddress" placeholder="请输入公司地址" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
              <el-input v-model="formData.unifiedSocialCreditCode" placeholder="请输入统一社会信用代码" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="公司经营状态" prop="companyStatus">
              <el-input v-model="formData.companyStatus" placeholder="请输入公司经营状态" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司法人" prop="legalPerson">
              <el-input v-model="formData.legalPerson" placeholder="请输入公司法人" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="公司类型" prop="typeId">
              <el-select v-model="formData.typeId" placeholder="请选择公司类型" @change="selectChange" style="width: 100%">
                <el-option v-for="(item, index) in options" :key="index" :label="item.typeName" :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建日期" prop="companyCreateDate">
              <el-date-picker style="width: 100%" v-model="formData.companyCreateDate" @change="change123"
                value-format="yyyy-MM-dd" type="date" placeholder="请选择创建时间" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="注册地址" prop="registeredAddress">
              <el-input v-model="formData.registeredAddress" placeholder="请输入注册地址" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册资本" prop="registeredCapital">
              <el-input v-model="formData.registeredCapital" placeholder="请输入注册资本" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="经营范围" prop="businessScope">
              <el-input v-model="formData.businessScope" placeholder="请输入经营范围" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司营业类型" prop="businessType">
              <el-input v-model="formData.businessType" placeholder="请输入公司营业类型" style="width: 100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="职位信息" prop="postMsgList">
        </el-form-item>
        <el-row v-for="(item, index) of formData.postMsgList" :key="index">
          <el-col :span="7">
            <el-form-item label="职位名称">
              <el-select popper-class="detailDialog_select-popper" v-model="item.postName" placeholder="请选择职位名称"
                style="width: 100%;">
                <el-option v-for="(data, index) in pname" :key="index" :label="data.label" :value="data.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="职位姓名">
              <el-input v-model="item.userName" placeholder="请输入职位姓名" style="width: 100%" />
            </el-form-item>
          </el-col>

          <el-col :span="7">
            <el-form-item label="联系电话">
              <el-input v-model="item.tel" placeholder="请输入联系电话" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-button size="mini" type="text" style="border-radius: 2px; color: #b93947; margin-left: 20px"
            @click="handleAdd(index)" v-if="formData.postMsgList.length == index + 1">
            <i class="el-icon-circle-plus-outline"></i>&nbsp;添加职位信息</el-button>
          <el-button style="margin-top: 2px" v-if="formData.postMsgList.length != 1" type="text" class="deleteBox"
            @click="handleDel(index)">
            <i class="el-icon-delete"></i>&nbsp;删除</el-button>
        </el-row>

        <el-form-item label="公司简介" prop="companyProfile">
          <div style="line-height: normal">
            <uEditor v-model="formData.companyProfile"></uEditor>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.companyId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>

      <!-- <div style="margin-bottom: 20px">
        <el-row>
          <el-col :span="24" align="right">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提 交</el-button
            >
          </el-col>
        </el-row>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  addType,
  getUploadToken,
  getTypes,
  getAllCompanyList,
  addCompanyInfo,
  setCompanyInfoStatus,
  modifyCompanyInfo,
  delCompanyInfo,
  getServiceList,
} from "../../request/http";
import uEditor from "../../components/item/item.vue";

import * as qiniu from "qiniu-js";

export default {
  data() {
    return {
      list: [],
      editOradd: "",
      imageUrl: "",
      options: [],
      postData: {
        token: "",
        key: "", //上传的Logo
      },
      pname: [
        {
          label: "人事主管",
          value: "HR",
        },
        {
          label: "销售主管",
          value: "SALE",
        },
        {
          label: "采购主管",
          value: "PURCHASE",
        },
        {
          label: "研发主管",
          value: "DEVELOP",
        },
      ],
      formData: {
        sort: "",
        businessType: "",
        companyName: "",
        managerName: "",
        account: "",
        companyLogoId: "",
        tel: "",
        companyAddress: "",
        companyStatus: "",
        typeId: null,
        businessScope: "",
        email: "",
        companyProfile: "",
        expireDateMsg: "",
        payDateMsg: "",
        serviceId: "",
        companyAbbreviation: "",
        websiteAddress: "",
        unifiedSocialCreditCode: "",
        legalPerson: "",
        companyCreateDate: "",
        registeredAddress: "",
        registeredCapital: "",
        postMsgList: [
          {
            postName: "",
            userName: "",
            tel: "",
          },
        ],
      },
      // postMsgList:{
      //   postName: "",
      //   userName: "",
      //   tel: ""

      // },

      rules: {
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        managerName: [
          { required: true, message: "账号管理员不能为空", trigger: "blur" },
        ],
        account: [
          { required: true, message: "会员主账号不能为空", trigger: "blur" },
          {

            pattern:
              /^\d{11}$/,
            message: "请输入正确的手机号码",
          },
        ],
        // companyLogoId: [
        //   { required: true, message: "公司logo不能为空", trigger: "blur" },
        // ],
        // tel: [
        //   { required: true, message: "公司联系电话不能为空", trigger: "blur" },
        // ],
        // companyAddress: [
        //   { required: true, message: "公司地址不能为空", trigger: "blur" },
        // ],
        // companyStatus: [
        //   { required: true, message: "公司经营状态不能为空", trigger: "blur" },
        // ],
        // typeId: [
        //   { required: true, message: "公司类型不能为空", trigger: "blur" },
        // ],
        // businessScope: [
        //   { required: true, message: "经营范围不能为空", trigger: "blur" },
        // ],
        // companyProfile: [
        //   { required: true, message: "公司简介不能为空", trigger: "blur" },
        // ],
        expireDateMsg: [
          { required: true, message: "到期时间不能为空", trigger: "blur" },
        ],
        payDateMsg: [
          { required: true, message: "缴费时间不能为空", trigger: "blur" },
        ],
        serviceId: [
          { required: true, message: "会员服务不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.getServiceList();

    console.log(this.$route.query);
    //editOradd 传过来的为 0 或者 1
    this.editOradd = this.$route.query.editOradd == 0 ? false : true;
    if (this.$route.query.obj) {
      let val = this.$route.query.obj;
      console.log(val.services);
      if (val.companyLogoId) {
        //  let services =JSON.stringify(val.services).split(",")
        this.imageUrl = "http://img.c-fol.cn/" + val.companyLogoId;
        // if(val.postMsgList){
        //   this.formData.postMsgList=val.postMsgList
        //   //  this.postMsgList.postName=val.postMsgList[0].postName
        //   //  this.postMsgList.userName=val.postMsgList[0].userName
        //   //  this.postMsgList.tel=val.postMsgList[0].tel
        // }
        this.formData = {
          postMsgList: val.postMsgList
            ? val.postMsgList
            : [
              {
                postName: "",
                userName: "",
                tel: "",
              },
            ],
          sort: val.sort,
          companyId: val.companyId,
          companyName: val.companyName,
          managerName: val.managerName,
          account: val.account,
          tel: val.tel,
          businessType: val.businessType,
          companyLogoId: val.companyLogoId,
          companyAddress: val.companyAddress,
          companyStatus: val.companyStatus,
          typeId: val.typeId,
          businessScope: val.businessScope,
          email: val.email,
          companyProfile: val.companyProfile,
          expireDateMsg: this.getLocalTime(val.expireTime),
          payDateMsg: val.payTime ? this.getLocalTime(val.payTime) : "",
          // services:services,
          serviceId: val.services,
          companyAbbreviation: val.companyAbbreviation,
          websiteAddress: val.websiteAddress,
          unifiedSocialCreditCode: val.unifiedSocialCreditCode,
          legalPerson: val.legalPerson,
          companyCreateDate: val.companyCreateDate,
          registeredAddress: val.registeredAddress,
          registeredCapital: val.registeredCapital,
        };
      } else {
        let services = JSON.stringify(val.services).split(",");
        // if(val.postMsgList){
        //    this.formData.postMsgList=val.postMsgList
        //     //  this.postMsgList.postName=val.postMsgList[0].postName
        //     //  this.postMsgList.userName=val.postMsgList[0].userName
        //     //  this.postMsgList.tel=val.postMsgList[0].tel
        //   }
        this.formData = {
          //      postMsgList:val.postMsgList? [{
          //   postName:val.postMsgList[0].postName,
          //   userName:val.postMsgList[0].userName,
          //   tel:val.postMsgList[0].tel
          // }] :"",
          sort: val.sort,
          companyId: val.companyId,
          companyName: val.companyName,
          managerName: val.managerName,
          account: val.account,
          tel: val.tel,
          postMsgList: val.postMsgList
            ? val.postMsgList
            : [
              {
                postName: "",
                userName: "",
                tel: "",
              },
            ],
          businessType: val.businessType,
          companyLogoId: val.companyLogoId,
          companyAddress: val.companyAddress,
          companyStatus: val.companyStatus,
          typeId: val.typeId,
          businessScope: val.businessScope,
          email: val.email,
          companyProfile: val.companyProfile,
          expireDateMsg: this.getLocalTime(val.expireTime),
          payDateMsg: val.payTime ? this.getLocalTime(val.payTime) : "",
          // services:services,
          serviceId: val.services,
          companyAbbreviation: val.companyAbbreviation,
          websiteAddress: val.websiteAddress,
          unifiedSocialCreditCode: val.unifiedSocialCreditCode,
          legalPerson: val.legalPerson,
          companyCreateDate: val.companyCreateDate,
          registeredAddress: val.registeredAddress,
          registeredCapital: val.registeredCapital,
        };
      }
    }
  },
  mounted() { },
  components: {
    uEditor,
  },

  methods: {
    sc(str) {
      //  console.log(str);
      this.formData.account = str.trim()
      console.log(this.formData.account.length);

    },
    handleAdd(index) {
      this.formData.postMsgList.push({
        postName: "",
        userName: "",
        tel: "",
      });
    },
    handleDel(index) {
      this.formData.postMsgList.splice(index, 1);
    },

    //获取列表
    async getServiceList() {
      let { data } = await getServiceList(this.queryParams);
      this.list = data;
      console.log(this.list);
    },

    //获取分类列表
    async getList() {
      let res = await getTypes({
        typeGroup: "COMPANY",
      });
      console.log(res);

      this.options = res.data;
    },
    //选择公司分类
    selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    //选择公司创建时间
    change123(val) {
      this.formData.companyCreateDate = val;
      console.log(this.formData.companyCreateDate);
    },
    //选择会员到期时间
    change456(val) {
      this.formData.expireDateMsg = val;
      console.log(this.formData.expireDateMsg);
    },
    //选择缴费时间
    change789(val) {
      this.formData.payDateMsg = val;
      console.log(this.formData.payDateMsg);
    },
    //选择会员服务
    change0(val) {
      // this.formData.serviceId = val;
      console.log(val);
    },
    zwchange(val) {
      this.postMsgList.postName = val;
    },

    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.formData.companyId) {
          //  this.postMsgList=={}? this.formData.postMsgList.push(this.postMsgList):this.formData.postMsgList=[]&&this.formData.postMsgList.push(this.postMsgList)
          //  if(this.postMsgList!=={}){
          //     this.formData.postMsgList=[]
          //    this.formData.postMsgList.push(this.postMsgList)
          //  }

          this.formData.serviceId = this.formData.serviceId.join(",");
          //  this.formData = JSON.parse(JSON.stringify(this.formData).replace(/serviceId/g,"services"));

          let { code, msg } = await modifyCompanyInfo(this.formData);
          if (this.formData.companyId && code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        } else {
          // if(this.postMsgList!=={}){
          //     this.formData.postMsgList=[]
          //    this.formData.postMsgList.push(this.postMsgList)
          //  }

          this.formData.serviceId = this.formData.serviceId.join(",");
          console.log(this.formData.serviceId);
          let { code, msg } = await addCompanyInfo(this.formData);
          console.log(code, msg);
          if (code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        }
      });
      // .then(() => {
      //   //  提示消息
      //   if (this.formData.companyId) {
      //     this.$message.success("编辑成功");
      //   } else {
      //     this.$message.success("新增成功");
      //   }

      //   this.goBack()

      // });
    },

    //弹窗取消函数
    btnCancel() {
      this.goBack();
    },
    // 提交
    // submitForm(formName) {
    //   let that = this;
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       if (that.editOradd) {

    //         updateLessonInfo(this.ruleForm).then((res) => {
    //           // console.log(res);
    //           if (res.code == 0) {
    //             this.$message({
    //               type: "success",
    //               message: "编辑成功",
    //             });
    //             this.goBack();
    //           }
    //         });
    //       } else {

    //         addLessonInfo(this.ruleForm).then((res) => {
    //           // console.log(res);
    //           if (res.code == 0) {
    //             this.$message({
    //               type: "success",
    //               message: "添加成功",
    //             });
    //             this.goBack();
    //           }
    //         });
    //       }
    //     } else {
    //       this.$message({
    //         type: "warning",
    //         message: "请把信息填写完整",
    //       });
    //       return false;
    //     }
    //   });
    // },

    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.companyLogoId = res.key;
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
    getLocalTime(nS) {
      let date = new Date(nS);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + "-" + month + "-" + day;
      // 2018-10-09
      return date;
    },
  },
};
</script>

<style >
.main {
  width: 85%;
  margin: 0 auto;
}

/deep/.el-select-dropdown {
  z-index: 999999999 !important
}

/deep/.popper-class {
  z-index: 999999999 !important
}

/deep/.el-select {
  z-index: 999999999 !important
}

.el-select-dropdown .el-popper .detailDialog_select-popper {
  z-index: 999999999 !important;
  top: auto !important;
  left: auto !important;
}
</style>
